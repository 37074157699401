<template>
	<div>
		<!-- 商家出账报表 -->
		<div class="queryBox mb20">
			<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
			<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期"
			 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
			<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
			<span class="inlineBlock mr20">
				<span>代理：</span>
				<el-select v-model="agent" placeholder="请选择" size="mini" clearable>
					<el-option v-for="item in agenttableData" :key="item.username" :label="item.name" :value="item.username"></el-option>
				</el-select>
			</span>
			<span class="inlineBlock mr20">
				<span>商家：</span>
				<el-select v-model="trader" placeholder="请选择" size="mini" clearable>
					<el-option v-for="item in tradertableData" :key="item.business_no" :label="item.business_name" :value="item.business_no"></el-option>
				</el-select>
			</span>
			<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">搜索</el-button>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini">
				<el-table-column label="序号" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
						<span v-else="">{{scope.$index + 1}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="代理号" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.agent_username.includes('总计')"></span>
						<el-link v-else="" type="primary" @click="setAgent(scope.row)">{{scope.row.agent_username}}({{scope.row.agent_name}})</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="shanghushu" label="商家号" width="200" align="center">
					<template slot-scope="scope">
						<div v-if="!scope.row.agent_username.includes('总计')" class="textCenter">{{scope.row.business_name}}</div>
						<div v-if="!scope.row.agent_username.includes('总计')" class="textCenter">{{scope.row.business_no}}</div>
					</template>
				</el-table-column>
				<el-table-column label="提交" align="center">
					<el-table-column prop="add_count" label="单数" align="center"></el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.add_money | tofixed}}</span>
							<!-- <span v-if="scope.row.agent_username.includes('总计')">{{scope.row.add_money | tofixed}}</span> -->
							<!-- <el-link v-else="" type="primary" @click="gotopage('/passagewayReport',scope.row)">{{scope.row.add_money | tofixed}}</el-link> -->
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="成功" align="center">
					<el-table-column prop="success_count" label="单数" align="center"></el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.success_money | tofixed}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				
				<!-- <el-table-column label="成功率" align="center">
					<el-table-column label="单数" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.success_count_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.success_money_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
				</el-table-column> -->
				<el-table-column label="自动成功率" align="center">
					<el-table-column label="单数" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.auto_success_count_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.auto_success_money_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="后台操作成功率" align="center">
					<el-table-column label="单数" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.manual_success_count_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.manual_success_money_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
				</el-table-column>
				
				<el-table-column label="搬砖工" align="center">
					<el-table-column label="单笔费率" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_carder | tofixed}}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column label="单笔费用" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_carder_num | tofixed}}</span>
						</template>
					</el-table-column> -->
				</el-table-column>
				<el-table-column label="代理" align="center">
					<el-table-column label="单笔费率" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_ag | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column label="单笔费用" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_ag_num | tofixed}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="平台" align="center">
					<el-table-column label="单笔费率" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_adm | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column label="单笔费用" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_adm_num | tofixed}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="商家手续费扣除" align="center">
					<el-table-column label="单笔费率" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_tra | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column label="单笔费用" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_num | tofixed}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="商家总扣除" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.trader_gold | tofixed}}</span>
					</template>
				</el-table-column>
				
				<!-- <el-table-column label="商家" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.trader_gold | tofixed}}</span>
					</template>
				</el-table-column> -->
			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				date: '',
				input: '',
				tableData: [],
				pageSizesArr: [100, 200,500,1000],
				pageSize: 100,
				total: 0,
				page: 1,
				paywayArr: [],
				pay_type: '',
				platformArr: [{
					value: '',
					label: '全部'
				}],
				platform: '',
				agent: '' ,//按代理查
				height:0,
				agenttableData:[],
				tradertableData:[],
				trader:''
			}
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.agentList()
			this.traderListtList()
			this.reportTrader()
			this.paywayAll()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				// this.date = [newDate, newDate]
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			paywayAll() {
				this.$api.paywayAll({}).then(res => {
					if (res.status === 1) {
						this.paywayArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//查看列表
			reportTrader() {
				let data = {
					// startdate: this.date,
					startdate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					size: this.pageSize,
					page: this.page,
					pay_type: this.pay_type,
					agent_username:this.agent,
					business_no:this.trader
				}
				// this.loading = true
				this.disLoading = true
				this.$api.reportTraderOut(data).then(res => {
					this.loading = false
					this.disLoading = false
					if (res.status == 1) {
						this.tableData = res.data.data
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			getData() {
				this.page = 1;
				this.reportTrader()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.reportTrader()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.reportTrader()
			},
			gotopage(path, item) {
				this.$router.push({
					path: path,
					query: {
						business_no: item.business_no,
						date:[this.$util.timestampToTime(Date.parse(new Date(this.date[0]))),this.$util.timestampToTime(Date.parse(new Date(this.date[1])))]
					}
				})
			},
			//点击代理名称  查询当前代理的数据
			setAgent(item) {
				this.agent = item.agent_username;
				this.page = 1
				this.reportTrader()
				console.log(this.agent)
			},
			//代理列表
			agentList() {
				this.$api.agentList({
					page: 1,
					size: this.traderagentpageSize
				}).then(res => {
					if (res.status === 1) {
						this.agenttableData = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
			//商家列表
			traderListtList() {
				this.$api.traderList({
					page: 1,
					size: this.traderagentpageSize
				}).then(res => {
					if (res.status === 1) {
						this.tradertableData = res.data.data						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	}
</script>

<style>
</style>
